import { STATUS } from "@/constants/statuses";
import BaseStore from "@/stateManagement/BaseStore";
import { Store, StoreConfig } from "@datorama/akita";
import { injectable } from "tsyringe";
import { singleton } from "tsyringe";

import { OfferEntity } from "../entities/OfferEntity";

export type CheckoutButtonModalState = {
    offer: OfferEntity | null;
    discounts: { variantId: number; discountCode: string }[];
    status: STATUS;
};

const createInitialState = (): CheckoutButtonModalState => ({
    offer: null,
    discounts: JSON.parse(
        localStorage.getItem("gw-up-discount-codes-checkout-button") || "[]"
    ),
    status: STATUS.VIRGIN,
});

@singleton()
@StoreConfig({ name: "CheckoutButtonModalStore", resettable: true })
class CheckoutButtonModalStore extends BaseStore<CheckoutButtonModalState> {
    constructor() {
        super(createInitialState());
    }
}

export default CheckoutButtonModalStore;
