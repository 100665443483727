export const ADD_TO_CART_BUTTON_MODAL = "ADD_TO_CART_BUTTON_MODAL";
export const CHECKOUT_BUTTON_MODAL = "CHECKOUT_BUTTON_MODAL";

export enum DISCOUNT_TYPE {
    FIXED_AMOUNT = "amount",
    PERCENTAGE = "percentage",
}

export enum SHIPPING_TYPE {
    FREE_SHIPPING = "free_shipping",
    CHARGE_SHIPPING = "charge_shipping",
}

export enum PRODUCT_LAYOUT {
    GRID = "GRID",
    TREE = "TREE",
}

export enum SkipOfferReasonEnum {
    PRODUCT_OUT_OF_STOCK = "PRODUCT_OUT_OF_STOCK",
    PRODUCT_STATUS_DRAFT = "PRODUCT_STATUS_DRAFT",
    PRODUCT_STATUS_ARCHIVED = "PRODUCT_STATUS_ARCHIVED",
    PRODUCT_NOT_IN_ONLINE_STORE = "PRODUCT_NOT_IN_ONLINE_STORE",
    PRODUCT_VARIANTS_WAS_DELETED = "PRODUCT_VARIANTS_WAS_DELETED",
}


export enum OFFER_WIDGET {
    ADD_TO_CART_BUTTON_MODAL_WIDGET = "add_to_cart_button_modal_button",
    CHECKOUT_BUTTON_MODAL_WIDGET = "checkout_button_modal_widget"
}