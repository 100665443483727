import "reflect-metadata";

import "./themes/default/default.css";
import "./themes/globals.css";

import { akitaDevtools } from "@datorama/akita";
import ApiClient from "@lib/ApiClient";
import AuthApiClient from "@lib/ApiClient/AuthApiClient";
import FromShopifyCurrencyCurrencyProvider from "@lib/CurrencyProvider/FromShopifyCurrencyCurrencyProvider";
import CustomerInfoProvider from "@lib/CustomerInfoProvider/CustomerInfoProvider";
import FetchAndXHRPatcher from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import MinilogLogger from "@lib/Logger";
import ShopifyCartApiService from "@modules/cart/api_services/CartService/ShopifyCartApiService";
import { CartQuery } from "@modules/cart/queries/CartQuery";
import { CartService } from "@modules/cart/services/CartService";
import { CartStore } from "@modules/cart/stores/CartStore";
import {
    cartManagerCounterUpdatersContextToken,
    cartManagerItemsUpdatersContextToken,
    cartQueryToken,
    cartServiceToken,
    cartStoreToken,
} from "@modules/cart/tokens";
import CartListener from "@modules/cart/utils/CartListener/CartListener";
import FetchOrXHRPatcherListenerStrategy from "@modules/cart/utils/CartListener/FetchOrXHRPatcherListenerStrategy";
import SendFormListenerStrategy from "@modules/cart/utils/CartListener/SendFormListenerStrategy";
import CartManagerCounterUpdatersContext from "@modules/cart/utils/CartManager/CartManagerCounterUpdatersContext";
import CartManagerItemsUpdatersContext from "@modules/cart/utils/CartManager/CartManagerItemsUpdatersContext";
import { TrackingApiService } from "@modules/tracking_events/services/TrackingApiService";
import { container, instanceCachingFactory } from "tsyringe";

import { ModalManager } from "@components/Modal/ModalManager";

import {
    UpsellApplicationConfigInterface,
    UpsellApplicationConfigToken,
} from "./apps/UpsellApplicationConfig";
import LocationController from "./lib/LocationController/LocationController";
import {
    apiClientToken,
    cartListenerToken,
    commonAuthApiClientToken,
    currencyProviderToken,
    fetchAndXhrPatcherToken,
    globalLoggerToken,
    locationControllerToken,
    modalManagerToken,
    sharedTokens,
    tokenManagerToken,
    trackingApiServiceToken,
} from "./tokens";

async function __upsellMain() {
    if (typeof window !== "undefined") {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (window as any).__GW_UPSELL_CONTAINER__ = container;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        (window as any).__GW_UPSELL_TOKENS__ = sharedTokens;
    }

    akitaDevtools();

    // logger
    const globalLogger = new MinilogLogger("global");
    globalLogger.info("Growave upsell main script is initialized!");
    container.registerInstance(globalLoggerToken, globalLogger);

    // (utils) customerInfoProvider
    const customerInfoProvider = new CustomerInfoProvider(globalLogger);
    customerInfoProvider.readFromPage();
    container.registerInstance(CustomerInfoProvider, customerInfoProvider);

    // (utils) location controller
    const locationController = new LocationController(window.location);
    container.registerInstance(locationControllerToken, locationController);

    // container.register(commonAuthApiClientToken, {
    //     useFactory: instanceCachingFactory((di) => {
    //         return new AuthApiClient(
    //             new ApiClient(
    //                 `/storefront-api/v2`,
    //                 undefined,
    //                 undefined,
    //                 di.resolve(globalLoggerToken)
    //             ),
    //             di.resolve(tokenManagerToken)
    //         );
    //     }),
    // });

    container.register(apiClientToken, {
        useFactory: instanceCachingFactory((di) => {
            return new ApiClient(
                `apps/gwupsell/v2`,
                undefined,
                undefined,
                di.resolve(globalLoggerToken)
            );
        }),
    });

    // ModalManager
    const modalManager = new ModalManager();
    container.registerInstance(modalManagerToken, modalManager);

    // (utils) fetchAndXHRPatcher
    const fetchAndXHRPatcher = new FetchAndXHRPatcher(globalLogger);
    fetchAndXHRPatcher.patch();
    container.registerInstance(fetchAndXhrPatcherToken, fetchAndXHRPatcher);

    // (utils) cartManager
    container.register(cartManagerCounterUpdatersContextToken, {
        useFactory: instanceCachingFactory(() => {
            return new CartManagerCounterUpdatersContext();
        }),
    });

    container.register(cartManagerItemsUpdatersContextToken, {
        useFactory: instanceCachingFactory(() => {
            return new CartManagerItemsUpdatersContext();
        }),
    });

    container.register(cartStoreToken, {
        useFactory: instanceCachingFactory(() => new CartStore()),
    });

    container.register(cartServiceToken, {
        useFactory: instanceCachingFactory((di) => {
            return new CartService(
                new ShopifyCartApiService(
                    new ApiClient("/"),
                    di.resolve(globalLoggerToken)
                ),
                di.resolve(globalLoggerToken),
                di.resolve(cartManagerCounterUpdatersContextToken),
                di.resolve(cartManagerItemsUpdatersContextToken),
                di.resolve(cartStoreToken)
            );
        }),
    });

    container.register(cartQueryToken, {
        useFactory: instanceCachingFactory((di) => {
            return new CartQuery(di.resolve(cartStoreToken));
        }),
    });

    // (utils) TrackingApiService
    const trackingApiService = new TrackingApiService(
        container.resolve(apiClientToken),
        globalLogger
    );
    container.registerInstance(trackingApiServiceToken, trackingApiService);

    // (utils) cartListener
    const cartListener = new CartListener(globalLogger);
    // cartListener.register(new SendFormListenerStrategy(globalLogger));
    cartListener.register(
        new FetchOrXHRPatcherListenerStrategy(globalLogger, fetchAndXHRPatcher)
    );
    container.registerInstance(cartListenerToken, cartListener);

    container.register<UpsellApplicationConfigInterface>(
        UpsellApplicationConfigToken,
        {
            useValue: {
                addToCartButtonModalWidgetPlaceholderSelector:
                    "form[action*='/cart/add']",
                checkoutButtonModalWidgetSelector:
                    ".gw-up-checkout-button-modal-widget-placeholder",
            },
        }
    );

    // (utils) currencyProvider
    const currencyProvider = new FromShopifyCurrencyCurrencyProvider(
        globalLogger
    );
    container.registerInstance(currencyProviderToken, currencyProvider);

    void import(
        /*webpackChunkName: "UpsellApplication" */ "@apps/UpsellApplication"
    ).then(({ UpsellApplication }) => {
        const upsellApplication = container.resolve(UpsellApplication);
        upsellApplication.init();
    });
}

void __upsellMain();
