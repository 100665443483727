import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import CurrencyProviderInterface from "@lib/CurrencyProvider/CurrencyProviderInterface";
import FetchAndXHRPatcher from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import GrowaveTokenManager from "@lib/TokenManager/GrowaveTokenManager";
import CartListenerInterface from "@modules/cart/utils/CartListener/CartListenerInterface";
import type { InjectionToken } from "tsyringe";

import { ModalManager } from "@components/Modal/ModalManager";
import { LoggerInterface } from "@interfaces/LoggerInterface";

import LocationControllerInterface from "./lib/LocationController/LocationControllerInterfce";
import { TrackingApiService } from "@modules/tracking_events/services/TrackingApiService";

export const commonAuthApiClientToken = Symbol(
    "commonAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const apiClientToken = Symbol(
    "apiClientToken"
) as InjectionToken<ApiClientInterface>;

export const tokenManagerToken = Symbol(
    "tokenManagerToken"
) as InjectionToken<GrowaveTokenManager>;

export const globalLoggerToken = Symbol(
    "globalLoggerToken"
) as InjectionToken<LoggerInterface>;

export const sharedTokens = {
    globalLoggerToken,
};

export const modalManagerToken = Symbol(
    "modalManagerToken"
) as InjectionToken<ModalManager>;

export const fetchAndXhrPatcherToken = Symbol(
    "fetchAndXhrPatcher"
) as InjectionToken<FetchAndXHRPatcher>;

export const cartListenerToken = Symbol(
    "cartListener"
) as InjectionToken<CartListenerInterface>;

export const currencyProviderToken = Symbol(
    "currencyProvider"
) as InjectionToken<CurrencyProviderInterface>;

export const locationControllerToken = Symbol(
    "locationControllerToken"
) as InjectionToken<LocationControllerInterface>;

export const trackingApiServiceToken = Symbol(
    "trackingApiServiceToken"
) as InjectionToken<TrackingApiService>;
