export const getJsonFromScript = (selector: string): unknown => {
    let parsedBody = null;
    const element = document.querySelector(selector);
    if (!element) {
        throw new Error(`element by selector (${selector}) not found`);
    }
    try {
        parsedBody = JSON.parse(element.textContent || "") as unknown;
    } catch (e: unknown) {
        throw new Error("json parse error");
    }
    return parsedBody;
};

export const getHumanReadableDate = (date: string | Date): string => {
    const newDate = new Date(date);

    return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    }).format(newDate);
};
