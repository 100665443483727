import { STATUS } from "@/constants/statuses";
import { Query } from "@datorama/akita";
import { Observable } from "rxjs";

import CartItem from "../entities/CartItem";
import { CartState, CartStore } from "../stores/CartStore";

export class CartQuery extends Query<CartState> {
    public constructor(readonly cartItemsStore: CartStore) {
        super(cartItemsStore);
    }

    selectEditItemStatus(variantId: number): Observable<STATUS> {
        return this.select(
            (state) => state.editItemStatuses[variantId] || STATUS.VIRGIN
        );
    }

    selectQuantityByVariantId(variantId: number): Observable<number> {
        return this.select(
            (state) =>
                state.items.find(
                    (item) =>
                        item.variant_id === variantId &&
                        item.properties?._offer_id
                )?.quantity || 0
        );
    }

    selectCartItems(): Observable<CartItem[]> {
        return this.select((state) => state.items);
    }

    selectCartToken(): Observable<string> {
        return this.select((state) => state.token);
    }

    selectCartInfoStatus(): Observable<STATUS> {
        return this.select((state) => state.status);
    }
}
