import { ApiClientResponse, JsonMimeType } from "@lib/ApiClient";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

interface TrackOfferBody {
    product_id: number;
    variant_id: number;
    quantity: number;
    upsell_value: number;
    offer_id: number;
    cart_token: string;
    currency: string;
}

@singleton()
export class TrackingApiService {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly logger: LoggerInterface
    ) {}

    trackAddToCartButtonModalOffer(
        reqBody: TrackOfferBody
    ): Observable<ApiClientResponse<unknown>> {
        this.logger.debug(
            "TrackingApiService.trackAddToCartButtonModalOffer is started",
            {
                reqBody,
            }
        );
        return this.apiClient
            .post({
                url: "/product-page/track-offer",
                body: JSON.stringify({ ...reqBody }),
                responseType: "text",
                contentType: JsonMimeType,
            })
            .pipe(
                tap(() => {
                    this.logger.debug(
                        "TrackingApiService.trackAddToCartButtonModalOffer is ready",
                        {
                            reqBody,
                        }
                    );
                })
            );
    }

    trackCheckoutButtonModalOffer(
        reqBody: TrackOfferBody
    ): Observable<ApiClientResponse<unknown>> {
        this.logger.debug(
            "TrackingApiService.trackCheckoutButtonModalOffer is started",
            {
                reqBody,
            }
        );
        return this.apiClient
            .post({
                url: "/cart-page/track-offer",
                body: JSON.stringify({ ...reqBody }),
                responseType: "text",
                contentType: JsonMimeType,
            })
            .pipe(
                tap(() => {
                    this.logger.debug(
                        "TrackingApiService.trackCheckoutButtonModalOffer is ready",
                        {
                            reqBody,
                        }
                    );
                })
            );
    }
}
