import { apiClientToken } from "@/tokens";
import ApiClient, { JsonMimeType } from "@lib/ApiClient";
import CartItemEntity from "@modules/cart/entities/CartItem";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { boolean, create, object } from "superstruct";
import { inject, injectable } from "tsyringe";

import { SkipOfferReasonEnum } from "../../../constants/constants";
import { DiscountEntity } from "../entities/DiscountEntity";
import { OfferEntity } from "../entities/OfferEntity";
import { DiscountSchema } from "../schemas/DiscountSchema";
import { OfferSchema } from "../schemas/OfferSchema";

export type GetOfferDTO = {
    product_id: number;
    variant_id: number | null;
    locale: string;
    customer_id: number | null;
    cart_token: string;
    cart_line_items: { product_id: number; variant_id: number }[];
};

export type GenerateDiscountDTO = {
    product_id: number;
    quantity: number;
    offer_id: number;
    variant_id: number;
};

export type CreateImpressionDTO = {
    offer_id: number;
    activity_type: "product_page";
    cart_token: string;
};

export type SkipOfferDTO = {
    offer_id: number;
    activity_type: "product_page";
    cart_token: string;
    note: {
        reason_code: SkipOfferReasonEnum;
        offer_id: number;
        product_id: number;
    }[];
};

@injectable()
export class AddToCartButtonModalApiService {
    public constructor(
        @inject(apiClientToken)
        private readonly apiClient: ApiClient
    ) {}

    getOffer(data: GetOfferDTO): Observable<OfferEntity> {
        return this.apiClient
            .post({
                url: "/product-page/offer",
                body: JSON.stringify(data),
                responseType: "json",
                contentType: JsonMimeType,
            })
            .pipe(
                map((response) => {
                    return create(response.body, OfferSchema);
                }),
                map((result) => {
                    return result;
                })
            );
    }

    generateDiscount(data: GenerateDiscountDTO): Observable<DiscountEntity> {
        return this.apiClient
            .post({
                url: "/product-page/generate-discount",
                body: JSON.stringify(data),
                responseType: "json",
                contentType: JsonMimeType,
            })
            .pipe(
                map((response) => {
                    return create(response.body, DiscountSchema);
                }),
                map((result) => {
                    return result;
                })
            );
    }

    createImpression(data: CreateImpressionDTO) {
        return this.apiClient
            .post({
                url: "/product-page/impression",
                body: JSON.stringify(data),
                responseType: "json",
                contentType: JsonMimeType,
            })
            .pipe(
                map((response) => {
                    return create(
                        response.body,
                        object({ success: boolean() })
                    );
                }),
                map((result) => {
                    return result;
                })
            );
    }

    skipOffer(data: SkipOfferDTO) {
        return this.apiClient
            .post({
                url: "/product-page/skip-offer",
                body: JSON.stringify(data),
                responseType: "json",
                contentType: JsonMimeType,
            })
            .pipe(
                map((response) => {
                    return create(
                        response.body,
                        object({ success: boolean() })
                    );
                }),
                map((result) => {
                    return result;
                })
            );
    }
}
