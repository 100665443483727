import { Infer, object, string } from "superstruct";
import { InjectionToken } from "tsyringe";

export const UpsellApplicationConfigSchema = object({
    addToCartButtonModalWidgetPlaceholderSelector: string(),
    checkoutButtonModalWidgetSelector: string(),
});

export type UpsellApplicationConfigInterface = Infer<
    typeof UpsellApplicationConfigSchema
>;

export const UpsellApplicationConfigToken = Symbol(
    "UpselltApplicationConfigToken"
) as InjectionToken<UpsellApplicationConfigInterface>;
