import { STATUS } from "@/constants/statuses";
import { globalLoggerToken } from "@/tokens";
import { applyTransaction } from "@datorama/akita";
import { Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import {
    CheckoutButtonModalApiService,
    CreateImpressionDTO,
    GenerateDiscountDTO,
    GetOfferDTO,
    SkipOfferDTO,
} from "../api_services/CheckoutButtonModalApiService";
import { DiscountEntity } from "../entities/DiscountEntity";
import { OfferEntity } from "../entities/OfferEntity";
import CheckoutButtonModalStore from "../stores/CheckoutButtonModalStore";

@singleton()
export class CheckoutButtonModalService {
    constructor(
        private readonly checkoutButtonModalStore: CheckoutButtonModalStore,
        private readonly checkoutButtonModalApiService: CheckoutButtonModalApiService,
        @inject(globalLoggerToken)
        private readonly logger: LoggerInterface
    ) {}

    getOffer(data: GetOfferDTO): Observable<OfferEntity> {
        this.logger.debug("CheckoutButtonModalService.getOffer is started");
        this.checkoutButtonModalStore.update((state) => {
            return {
                ...state,
                status:
                    state.status === STATUS.VIRGIN
                        ? STATUS.INITIAL_LOADING
                        : STATUS.LOADING,
            };
        });
        return this.checkoutButtonModalApiService.getOffer(data).pipe(
            catchError((error: unknown) => {
                this.logger.debug(
                    "CheckoutButtonModalService.getOffer is errored",
                    { error }
                );
                this.checkoutButtonModalStore.setError(true);
                throw error;
            }),
            tap((offer) => {
                this.logger.debug(
                    "CheckoutButtonModalService.getOffer is ready"
                );
                applyTransaction(() => {
                    this.checkoutButtonModalStore.update((state) => ({
                        ...state,
                        offer: offer,
                        status: STATUS.READY,
                    }));
                });
            })
        );
    }

    generateDiscount(data: GenerateDiscountDTO): Observable<DiscountEntity> {
        this.logger.debug(
            "CheckoutButtonModalService.generateDiscount is started"
        );

        return this.checkoutButtonModalApiService.generateDiscount(data).pipe(
            catchError((error: unknown) => {
                this.logger.debug(
                    "CheckoutButtonModalService.generateDiscount is errored",
                    { error }
                );
                this.checkoutButtonModalStore.setError(true);
                throw error;
            }),
            tap(({ discount_code }) => {
                this.logger.debug(
                    "CheckoutButtonModalService.getOffer is ready"
                );
                applyTransaction(() => {
                    this.checkoutButtonModalStore.update((state) => ({
                        ...state,
                        discounts: [
                            ...state.discounts,
                            {
                                variantId: data.variant_id,
                                discountCode: discount_code,
                            },
                        ],
                        offer: state.offer
                            ? {
                                  ...state.offer,
                                  offer: {
                                      ...state.offer.offer,
                                      main_offer: {
                                          ...state.offer.offer.main_offer,
                                          offer_products:
                                              state.offer.offer.main_offer.offer_products.map(
                                                  (offerProduct) => {
                                                      if (
                                                          Number(
                                                              offerProduct
                                                                  .product.id
                                                          ) === data.product_id
                                                      ) {
                                                          return {
                                                              ...offerProduct,
                                                              discount_code:
                                                                  discount_code,
                                                          };
                                                      }
                                                      return offerProduct;
                                                  }
                                              ),
                                      },
                                  },
                              }
                            : null,
                    }));
                });
            })
        );
    }

    removeDiscount(code: string): void {
        applyTransaction(() => {
            this.checkoutButtonModalStore.update((state) => ({
                ...state,
                discounts: state.discounts.filter(
                    ({ discountCode }) => discountCode !== code
                ),
            }));
        });
    }

    createImpression(
        data: CreateImpressionDTO
    ): Observable<{ success: boolean }> {
        this.logger.debug(
            "CheckoutButtonModalService.createImpression is started"
        );
        return this.checkoutButtonModalApiService.createImpression(data).pipe(
            catchError((error: unknown) => {
                this.logger.debug(
                    "CheckoutButtonModalService.createImpression is errored",
                    { error }
                );
                this.checkoutButtonModalStore.setError(true);
                throw error;
            }),
            tap(() => {
                this.logger.debug(
                    "CheckoutButtonModalService.createImpression is ready"
                );
            })
        );
    }

    skipOffer(data: SkipOfferDTO): Observable<{ success: boolean }> {
        this.logger.debug("CheckoutButtonModalService.skipOffer is started");
        return this.checkoutButtonModalApiService.skipOffer(data).pipe(
            catchError((error: unknown) => {
                this.logger.debug(
                    "CheckoutButtonModalService.skipOffer is errored",
                    { error }
                );
                this.checkoutButtonModalStore.setError(true);
                throw error;
            }),
            tap(() => {
                this.logger.debug(
                    "CheckoutButtonModalService.skipOffer is ready"
                );
            })
        );
    }

    resetCheckoutButtonModalStore() {
        this.checkoutButtonModalStore.reset();
    }
}
