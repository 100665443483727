import {
    any,
    array,
    boolean,
    nullable,
    number,
    object,
    optional,
    string,
} from "superstruct";

export const ProductVariantSchema = object({
    compare_at_price: nullable(string()),
    id: number(),
    inventory_management: nullable(string()),
    inventory_policy: string(),
    inventory_quantity: number(),
    option1: nullable(string()),
    option2: nullable(string()),
    option3: nullable(string()),
    price: string(),
    position: number(),
    title: string(),
    image: nullable(string()),
    image_id: nullable(number()),
});

const ProductOptionSchema = object({
    id: number(),
    name: string(),
    position: number(),
    values: array(string()),
});

const ProductImageSchema = object({
    alt: nullable(string()),
    src: string(),
    id: nullable(number()),
});

export const ShopifyProductSchema = object({
    id: number(),
    title: string(),
    review_count: nullable(any()),
    review_rating: nullable(any()),
    published_at: nullable(string()),
    status: string(),
    variants: array(ProductVariantSchema),
    options: array(ProductOptionSchema),
    images: nullable(array(ProductImageSchema)),
});

const AddToCartSettingSchema = object({
    layout: string(),
    offer_product_price: nullable(string()),
    button_bg_color: string(),
    button_text_color: string(),
});

export const OfferProduct = object({
    id: optional(number()),
    product: ShopifyProductSchema,
    variant_ids: array(number()),
    discount_amount: number(),
    discount_type: string(),
    use_compare_price: boolean(),
    shipping_type: nullable(string()),
    shipping_charge_value: nullable(number()),
    shipping_charge_per_item: boolean(),
    show_review_rating: boolean(),
    show_quantity_selector: boolean(),
    offer_products_title: string(),
    offer_products_description: string(),
    post_purchase_setting: nullable(any()),
    ty_setting: nullable(any()),
    add_to_cart_setting: nullable(AddToCartSettingSchema),
    discount_code: optional(string()),
});

export const OfferSchema = object({
    offer: object({
        main_offer: object({
            offer_id: number(),
            heading: nullable(string()),
            timer: nullable(number()),
            timer_text: nullable(string()),
            hide_timer: boolean(),
            bg_color: nullable(string()),
            offer_products: array(OfferProduct),
        }),
        static_translations: object({
            post_purchase_pay_button: string(),
            post_purchase_or_button: string(),
            post_purchase_decline_button: string(),
            post_purchase_shipping_label: string(),
            post_purchase_taxes_label: string(),
            post_purchase_free_label: string(),
            post_purchase_quantity_label: string(),
            save_label: string(),
            percentage_label: string(),
            ty_page_show_more_button: string(),
            ty_page_show_less_button: string(),
            unavailable_label: string(),
            add_button_text: string(),
            added_button_text: string(),
            continue_button_text: string(),
            locale: string(),
        }),
    }),
    settings: object({
        currencyChar: string(),
        moneyFormat: string(),
        moneyWithCurrencyFormat: string(),
    }),
    customer_first_name: nullable(string()),
});
