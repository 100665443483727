import { STATUS } from "@/constants/statuses";
import { Store, StoreConfig } from "@datorama/akita";

import CartItem from "../entities/CartItem";

export type CartState = {
    status: STATUS;
    items: CartItem[];
    editItemStatuses: {
        [variantId: number]: STATUS;
    };
    token: string;
};

const createInitialState = (): CartState => {
    return {
        status: STATUS.VIRGIN,
        items: [],
        editItemStatuses: {},
        token: "",
    };
};

@StoreConfig({
    name: "CartStore",
})
export class CartStore extends Store<CartState> {
    public constructor() {
        super(createInitialState());
    }
}
