import LocationControllerInterface from "./LocationControllerInterfce";

class LocationController implements LocationControllerInterface {
    constructor(private readonly location: Location) {}
    goTo(link: string): void {
        this.location.href = link;
    }
    getPathname(): string {
        return this.location.pathname;
    }
    getHref(): string {
        return this.location.href;
    }
    getOrigin(): string {
        return this.location.origin;
    }
    getSearchParams(): URLSearchParams {
        const url = new URL(this.location.href);
        return url.searchParams;
    }
    redirectToWithParams(
        baseURL: string,
        params: Record<string, string>
    ): void {
        // Проверка и обработка базового URL
        try {
            const url = new URL(baseURL, this.location.origin);

            // Добавление параметров поиска
            Object.keys(params).forEach((key) => {
                url.searchParams.set(key, params[key]);
            });

            // Выполнение редиректа
            this.location.href = url.toString();
        } catch (error) {
            console.error("Invalid URL:", baseURL, error);
        }
    }
}

export default LocationController;
