import { STATUS } from "@/constants/statuses";
import { Store, StoreConfig } from "@datorama/akita";
import { singleton } from "tsyringe";

export type BaseStoreState = {
    status: STATUS;
};

const createBaseInitialState = (): BaseStoreState => ({
    status: STATUS.VIRGIN,
});

@singleton()
@StoreConfig({ name: "BaseStore" })
class BaseStore<S> extends Store<BaseStoreState & S> {
    constructor(initialState: S) {
        super({ ...createBaseInitialState(), ...initialState });
    }

    public setStatus(status: STATUS): void {
        this.update((state) => ({ ...state, status }));
    }

    public getStatus(): STATUS {
        return this.getValue().status;
    }
}

export default BaseStore;
