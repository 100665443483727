import { STATUS } from "@/constants/statuses";
import BaseStore from "@/stateManagement/BaseStore";
import { Store, StoreConfig } from "@datorama/akita";
import { injectable } from "tsyringe";
import { singleton } from "tsyringe";

import { OfferEntity } from "../entities/OfferEntity";

export type AddToCartButtonModalState = {
    offer: OfferEntity | null;
    discounts: { variantId: number; discountCode: string }[];
    status: STATUS;
};

const createInitialState = (): AddToCartButtonModalState => ({
    offer: null,
    discounts: JSON.parse(localStorage.getItem("gw-up-discount-codes-add-to-cart-button") || "[]"),
    status: STATUS.VIRGIN,
});

@singleton()
@StoreConfig({ name: "AddToCartButtonModalStore", resettable: true })
class AddToCartButtonModalStore extends BaseStore<AddToCartButtonModalState> {
    constructor() {
        super(createInitialState());
    }
}

export default AddToCartButtonModalStore;
