import { AddToCartButtonModalService } from "@apps/widgets/add_to_cart_button_modal_widget/services/AddToCartButtonModalService";
import AddToCartButtonModalStore from "@apps/widgets/add_to_cart_button_modal_widget/stores/AddToCartButtonModalStore";
import { CheckoutButtonModalService } from "@apps/widgets/checkout_button_widget/services/CheckoutButtonModalService";
import FetchAndXHRPatcher, {
    CatchedRequest,
} from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import { Observable, combineLatest, from, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { container } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import CartListenerStrategyInterface from "./CartListenerStrategyInterface";
import { ChangeCartEvent } from "./types";

class FetchOrXHRPatcherListenerStrategy
    implements CartListenerStrategyInterface
{
    constructor(
        private readonly logger: LoggerInterface,
        private readonly fetchAndXhrPatcher: FetchAndXHRPatcher
    ) {}
    listen(): Observable<ChangeCartEvent> {
        return this.fetchAndXhrPatcher.requests$.pipe(
            filter((request: CatchedRequest) => {
                return request.url.includes("/cart/change");
            }),
            filter(
                (
                    request: CatchedRequest
                ): request is Omit<CatchedRequest, "body"> & {
                    body: FormData | string;
                } => {
                    return (
                        request.body instanceof FormData ||
                        typeof request.body === "string"
                    );
                }
            ),
            // combine request and response for waiting response before processins next steps
            switchMap((request) => {
                return combineLatest([of(request), from(request.response)]);
            }),
            map(([request, response]) => {
                response.json().then((data) => {
                    if (data.item_count === 0) {
                        localStorage.removeItem(
                            "gw-up-discount-codes-add-to-cart-button"
                        );

                        container
                            .resolve(AddToCartButtonModalService)
                            .resetAddToCartButtonModalStore();

                        localStorage.removeItem(
                            "gw-up-discount-codes-checkout-button"
                        );
                        container
                            .resolve(CheckoutButtonModalService)
                            .resetCheckoutButtonModalStore();
                    }
                });
                if (typeof request.body === "string") {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
                    return {
                        quantity: JSON.parse(request.body)[
                            "quantity"
                        ] as string,
                        line: JSON.parse(request.body)["line"] as string,
                    } as ChangeCartEvent;
                } else if (request.body instanceof FormData) {
                    return {
                        quantity: request.body.get("quantity") as string,
                        line: request.body.get("line") as string,
                    } as ChangeCartEvent;
                }
                throw new Error("not found in body of request to /cart/change");
            })
        );
    }
}

export default FetchOrXHRPatcherListenerStrategy;
