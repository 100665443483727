// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gw-up-list-reset-default-styles {
    margin: 0;
    padding: 0;
    list-style: none;
}

.gw-up-link-reset-default-styles {
    text-decoration: none;
    color: inherit;
}

.gw-up-hide-elements {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
}

.gw-up-default-reset {
    margin: 0;
    padding: 0;
    border: none;
}
`, "",{"version":3,"sources":["webpack://./src/themes/globals.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,SAAS;IACT,UAAU;IACV,YAAY;AAChB","sourcesContent":[".gw-up-list-reset-default-styles {\n    margin: 0;\n    padding: 0;\n    list-style: none;\n}\n\n.gw-up-link-reset-default-styles {\n    text-decoration: none;\n    color: inherit;\n}\n\n.gw-up-hide-elements {\n    position: absolute;\n    width: 1px;\n    height: 1px;\n    overflow: hidden;\n    clip: rect(0 0 0 0);\n}\n\n.gw-up-default-reset {\n    margin: 0;\n    padding: 0;\n    border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
